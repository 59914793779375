<template>
  <el-card>
    <div slot="header" class="card-header">
      <h3 style="margin: 0">DEPARTMENT-SECTION MAINTENANCE</h3>
      <div>
        <el-select v-model="bd_id" placeholder="Select Department" filterable @change="getSections">
          <el-option v-for="department in departments" :key="department.id" :value="department.id" :label="department.name"></el-option>
        </el-select>
        <el-button v-if="bd_id" type="primary" style="margin-left: 1rem;" @click="dialog = true">Add New Section</el-button>
      </div>
    </div>
    <el-dialog title="Add New Section" :visible.sync="dialog">
      <el-form label-width="120px">
        <el-form-item label="Section Name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="Section Name">
          <el-input v-model="form.code"></el-input>
        </el-form-item>
        <el-form-item label="Head Name">
          <el-select v-model="form.section_head_em_id">
            <el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialog = false">Cancel</el-button>
        <el-button type="primary" @click="addSection">Confirm</el-button>
      </span>
    </el-dialog>
    <el-table :data="data" border max-height="580" class="hidden-sm-and-down">
      <el-table-column label="ID" prop="id"></el-table-column>
      <!-- <el-table-column label="DEPARTMENT" prop="name"></el-table-column> -->
      <el-table-column label="CODE" prop="code"></el-table-column>
      <el-table-column label="NAME" prop="name"></el-table-column>
      <el-table-column label="SECTION HEAD" prop="section_head_em_id_name"></el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  data () {
    return {
      departments: [],
      bd_id: null,
      data: [],
      employees: [],
      dialog: false,
      form: {
        bd_id: null,
        code: null,
        name: null,
        section_head_em_id: null,
        immediate1_em_id: 0,
        immediate2_em_id: 0,
        immediate3_em_id: 0,
        immediate4_em_id: 0,
        immediate5_em_id: 0,
        immediate6_em_id: 0
      }
    }
  },
  mounted () {
    this.getDepartments()
  },
  methods: {
    getDepartments () {
      this.$http
        .get('backend.department-list-by-company/1')
        .then(res => {
          this.departments = res.data.body
        })
    },
    getSections () {
      this.$http
        .get(`backend.department.section-list/${this.bd_id}`)
        .then(res => {
          this.data = res.data.StatusCode ? res.data.body : []
          this.getDepartmentEmployees()
        })
    },
    getDepartmentEmployees () {
      this.$http
        .get(`employee-department-list/${this.bd_id}`)
        .then(res => {
          this.employees = res.data.StatusCode ? res.data.body : []
        })
    },
    addSection () {
      this.dialog = false
      this.form.bd_id = this.bd_id
      this.$http
        .post('backend.department.section', this.form)
        .then(() => {
          this.$message({
            message: 'Section created',
            type: 'success'
          })
          this.getSections()
        })
    }
  }
}
</script>
